<template>
    <div class="row">
        <div class="col mt-2">
            <div class="card bg-soft-info mb-3 check-info-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col display-6">
                            Edit Profile data
                        </div>
                    </div>
                    <div v-if="successMessage" class="row">
                        <div class="col">
                            <div class="alert alert-success alert-dismissible fade show mt-4" role="alert">
                                {{ successMessage }}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col">
                            <ChangePasswordForm v-if="user" :user="user" 
                                :validations="passwordValidations"
                                @submit="onPasswordChange"/>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col">
                            <button @click="routeBack" class="btn btn-outline-secondary float-end">
                                <i class="bi bi-arrow-return-left"></i> Go back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import manufacturingApi from "@/api/manufacturingApi"
import ChangePasswordForm from '../components/ChangePasswordForm.vue';


export default {
    components: {
        ChangePasswordForm
    },

    setup() {
        const router = useRouter()

        const user = ref(null)
        const passwordValidations = ref({})
        const successMessage = ref('')

        onMounted(async () => {
            const { data } = await manufacturingApi.get(
                `/auth/user/`, { withCredentials: true } )
            user.value = data
        })

        return {
            user,
            passwordValidations,
            successMessage,

            onPasswordChange: ($event, formData) => {
                console.log("Update user password");
                console.log($event);
                console.log(formData);
                manufacturingApi.post(`/auth/password/change/`, formData,
                    { withCredentials: true } )
                    .then((response) => {
                        console.log(response);
                        const { data } = response
                        if (data.detail) {
                            successMessage.value = data.detail
                        }                            
                    })
                    .catch((error) => {
                        console.log(error.response);
                        const { data } = error.response
                        passwordValidations.value = data
                    })
                
            },

            routeBack: () => router.back()
        }
    },
}
</script>