<template>
<div class="mb-3">
    <label for="input-old_password" class="form-label">Old password</label>
    <BasicInput 
        v-model="old_password"
        inputType="password"
        :invalidFeedbackText="valErrors.old_password"
        textHelp="Introduce your old password."/>
</div>
<div class="mb-3">
    <label for="input-new_password1" class="form-label">New password</label>
    <BasicInput 
        v-model="new_password1"
        inputType="password"
        autocomplete="new-password"
        :invalidFeedbackText="valErrors.new_password1"
        textHelp="Introduce desired new password."/>
</div>
<div class="mb-3">
    <label for="input-new_password2" class="form-label">Repeat new password</label>
    <BasicInput 
        v-model="new_password2"
        inputType="password"
        autocomplete="new-password"
        :invalidFeedbackText="valErrors.new_password2"
        textHelp="Repeat new password."/>
</div>
<div class="d-grid gap-2 mx-auto">
    <button id="btn-submit"
            type="button" 
            @click="submit"
            class="btn btn-success btn-block p-2 shadow">
        Update password
    </button>   
</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

import BasicInput from '../../shared/components/BasicInput';

export default {
    components: {
        BasicInput
    },
    props: {
        user: {
            required: true,
            type: Object
        },
        validations: {
            type: Object
        }
    },

    emits: ['submit'],

    setup(props, context) {
        const old_password = ref(null)
        const new_password1 = ref(null)
        const new_password2 = ref(null)

        const valErrors = ref({
            'old_password': '',
            'new_password1': '',
            'new_password2': ''
        })

        const cleanValidations = () => {
            for (const field in valErrors) {
                valErrors[field] = ''
            }
        }

        const cleanForm = () =>  {
            cleanValidations()
            old_password.value = null
            new_password1.value = null
            new_password2.value = null
        }

        watch(() => props.validations, (newValidations) => {
            cleanValidations()
            for (const field in newValidations) {
                console.log(`Error in field ${field}: ${newValidations[field]}`);
                valErrors[field] = newValidations[field][0]
            }   
        })

        onMounted(() => {
            cleanForm()
        })

        return {
            old_password,
            new_password1,
            new_password2,
            valErrors,

          
            submit: ($event) => {
                const formData = { 
                    old_password: old_password.value, 
                    new_password1: new_password1.value,
                    new_password2: new_password2.value 
                }
                context.emit('submit', $event, formData)
            }  
        }
    }
}
</script>

<style>

</style>